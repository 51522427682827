exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-market-distributors-tsx": () => import("./../../../src/pages/market/distributors.tsx" /* webpackChunkName: "component---src-pages-market-distributors-tsx" */),
  "component---src-pages-market-hookah-cafe-tsx": () => import("./../../../src/pages/market/hookah-cafe.tsx" /* webpackChunkName: "component---src-pages-market-hookah-cafe-tsx" */),
  "component---src-pages-market-hotel-and-restaurant-tsx": () => import("./../../../src/pages/market/hotel-and-restaurant.tsx" /* webpackChunkName: "component---src-pages-market-hotel-and-restaurant-tsx" */),
  "component---src-pages-market-petrol-station-tsx": () => import("./../../../src/pages/market/petrol-station.tsx" /* webpackChunkName: "component---src-pages-market-petrol-station-tsx" */),
  "component---src-pages-market-private-label-customer-tsx": () => import("./../../../src/pages/market/private-label-customer.tsx" /* webpackChunkName: "component---src-pages-market-private-label-customer-tsx" */),
  "component---src-pages-market-retail-and-supermarket-chains-tsx": () => import("./../../../src/pages/market/retail-and-supermarket-chains.tsx" /* webpackChunkName: "component---src-pages-market-retail-and-supermarket-chains-tsx" */),
  "component---src-pages-markets-tsx": () => import("./../../../src/pages/markets.tsx" /* webpackChunkName: "component---src-pages-markets-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-product-cube-coconutshell-briquettes-tsx": () => import("./../../../src/pages/product/cube-coconutshell-briquettes.tsx" /* webpackChunkName: "component---src-pages-product-cube-coconutshell-briquettes-tsx" */),
  "component---src-pages-product-finger-coconutshell-briquettes-tsx": () => import("./../../../src/pages/product/finger-coconutshell-briquettes.tsx" /* webpackChunkName: "component---src-pages-product-finger-coconutshell-briquettes-tsx" */),
  "component---src-pages-product-flat-or-brick-coconutshell-briquettes-tsx": () => import("./../../../src/pages/product/flat-or-brick-coconutshell-briquettes.tsx" /* webpackChunkName: "component---src-pages-product-flat-or-brick-coconutshell-briquettes-tsx" */),
  "component---src-pages-product-hexagonal-coconutshell-briquettes-tsx": () => import("./../../../src/pages/product/hexagonal-coconutshell-briquettes.tsx" /* webpackChunkName: "component---src-pages-product-hexagonal-coconutshell-briquettes-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

